import axios from 'axios';

export default class NetworkWorkshop {
  static getListWorkshop(config) {
    return axios.get('workshop', config);
  }

  static createWorkshop(data, config) {
    return axios.post('workshop', data, config);
  }

  static updateWorkshop(id, data, config) {
    return axios.put(`workshop/${id}`, data, config);
  }

  static deleteWorkshop(id, config) {
    return axios.delete(`workshop/${id}`, config);
  }

  static sendReflexionWorkshop(data, config) {
    return axios.post('workshop/send-reflexion', data, config);
  }

  static getQuestionAnswers(data, config) {
    return axios.post('workshop/get-question-answers', data, config);
  }
}
