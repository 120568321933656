<template>
  <div class="workshop-response-container">
    <div class="item-container">
      <div class="heading-container">
        <div class="toggle-container">
          <font-awesome-icon
            v-if="!isSentReflection"
            @click.stop="toggleQuestionContainer"
            :class="{ 'chevron-icon-up': showQuestions }"
            class="chevron-icon-down cursor-pointer"
            icon="fa-chevron-down"
          />
          <font-awesome-icon v-else class="chevron-icon-down hidden" icon="fa-chevron-down" />
          <p id="title">{{ workshop.workshopTitle }}</p>
        </div>
        <p id="workshop-date">
          <font-awesome-icon class="calendar-icon" icon="fa-calendar" />
          {{ formattedDate }}
        </p>
        <p>
          {{ workshop.workshopFacilitator?.name }}
        </p>

        <div class="heading-container-facilitator">
          <span
            v-if="isSentReflection"
            @click.stop="toggleShowReflectionParticipants"
            class="cursor-pointer"
          >
            {{ $t('WorkshopComponent.SendReflection') }}
          </span>
          <span v-else class="disabled"> {{ $t('WorkshopComponent.SendReflection') }} </span>
          <font-awesome-icon
            @click="toggleEditWorkshop"
            class="cursor-pointer edit-icons"
            icon="fa-pen "
          />
          <font-awesome-icon
            @click="deleteWorkshop"
            class="cursor-pointer edit-icons"
            icon="fa-trash-can"
          />
        </div>
      </div>
      <div :class="{ showQuestions: showQuestions }" class="all-questions-container">
        <workshop-question-component
          v-for="(question, index) in questions"
          :key="index"
          :question="question"
        >
        </workshop-question-component>
      </div>
    </div>
    <workshop-form-component @submit="handleSubmitEdit" :value="workshopEditData" v-if="showEdit" />
  </div>
  <pop-up-confirmation-component
    v-if="showPopup"
    :message="popupMessage"
    :confirmButtonText="deleteButtonText"
    :cancelButtonText="cancelButtonText"
    @cancel="onPopupCancel"
    @confirm="onPopupConfirm"
  />
</template>
<script>
import WorkshopQuestionComponent from '@/components/Home/WorkshopComponent/QuestionComponent.vue';
import WorkshopFormComponent from './WorkshopFormComponent.vue';
import PopUpConfirmationComponent from '@/components/global/PopUpConfirmationComponent.vue';
import NetworkWorkshop from '@/helpers/networking/NetworkWorkshop';

export default {
  name: 'WorkshopResponseComponent',
  props: {
    workshop: Object,
  },
  components: { WorkshopQuestionComponent, PopUpConfirmationComponent, WorkshopFormComponent },
  data() {
    return {
      showQuestions: false,
      questions: [],
      questionCount: [1, 2, 3, 4],
      showParticipants: false,
      showPopup: false,
      showEdit: false,
      popupMessage: this.$t('ToastComponent.workshop.workshopDeletedConfirm'),
      deleteButtonText: this.$t('KssTaskCreatedComponent.popup.deleteButton'),
      cancelButtonText: this.$t('KssTaskCreatedComponent.popup.cancelButton'),
    };
  },
  methods: {
    convertUTCDateToLocalInput(utcDateString) {
      if (!utcDateString) return '';
      const utcDate = new Date(utcDateString);
      const year = utcDate.getFullYear();
      const month = (utcDate.getMonth() + 1).toString().padStart(2, '0');
      const day = utcDate.getDate().toString().padStart(2, '0');
      const hours = utcDate.getHours().toString().padStart(2, '0');
      const minutes = utcDate.getMinutes().toString().padStart(2, '0');
      const localDatetimeString = `${year}-${month}-${day}T${hours}:${minutes}`;
      return localDatetimeString;
    },
    toggleEditWorkshop() {
      this.showEdit = !this.showEdit;
    },
    deleteWorkshop() {
      this.showPopup = true;
    },
    onPopupCancel() {
      this.showPopup = false;
    },
    async handleSubmitEdit(params) {
      try {
        this.$store.dispatch('updateWorkshop', params);
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: this.$t('ToastComponent.workshop.workshopUpdated'),
          type: 'success',
        });
        this.toggleEditWorkshop();
        window.scrollTo({ top: 0, behavior: 'smooth' });
      } catch (error) {
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: error.response.data.message,
          type: 'error',
        });
      }
    },
    async onPopupConfirm() {
      try {
        this.$store.dispatch('deleteWorkshop', this.workshop._id);
        this.showPopup = false;
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: this.$t('ToastComponent.workshop.workshopDeleted'),
          type: 'success',
        });
      } catch (error) {
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: this.$t('ToastComponent.workshop.workshopDeletedError'),
          type: 'error',
        });
      }
    },
    async toggleQuestionContainer() {
      this.showQuestions = !this.showQuestions;
      if (this.showQuestions === true) {
        try {
          const { data } = await NetworkWorkshop.getQuestionAnswers({
            taskId: this.workshop.relatedTaskId,
          });
          this.questions = data.questions;
        } catch (error) {
          this.questions = [];
        }
      }
    },
    toggleShowReflectionParticipants() {
      this.showParticipants = !this.showParticipants;
      try {
        this.$store.commit('setSelectedWorkshop', this.workshop);
        this.$store.commit('addFrontendOverlay', {
          id: Math.random(),
          type: 'workshopParticipants',
        });
      } catch (error) {
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: 'error',
          type: 'error',
        });
      }
    },
  },
  computed: {
    workshopEditData() {
      return {
        ...this.workshop,
        dateWorkshopBegin: this.convertUTCDateToLocalInput(this.workshop.dateWorkshopBegin),
        dateWorkshopEnd: this.convertUTCDateToLocalInput(this.workshop.dateWorkshopEnd),
      };
    },
    formattedDate() {
      const date = new Date(this.workshop.createdAt);
      return `${`0${date.getDate()}`.slice(-2)}.${`0${date.getMonth() + 1}`.slice(
        -2,
      )}.${date.getFullYear()}`;
    },
    isSentReflection() {
      return !this.workshop.sentReflexion;
    },
  },
};
</script>
<style scoped>
.workshop-response-container {
  max-width: 1440px;
  background: var(--color-light);
  border-radius: var(--card-border-radius);
  padding: var(--card-padding);
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  margin-top: 20px;
}

.item-container {
  display: flex;
  flex-direction: column;
}

.heading-container {
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  width: 100%;
}

.heading-container p:nth-of-type(1) {
  margin-left: 10px;
}

.heading-container p {
  margin-left: 15px;
}

.toggle-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.all-questions-container {
  display: none;
}

.showQuestions {
  display: flex;
  flex-direction: column;
}

.chevron-icon-down {
  margin: 0 0 0 10px;
  transition: 100ms ease-in;
}

.chevron-icon-up {
  transform: rotate(180deg);
}

.cursor-pointer {
  cursor: pointer;
}

.calendar-icon {
  padding: 0;
}

.edit-icons {
  color: var(--color-disabled);
}

.edit-icons:hover {
  color: var(--color-orange);
  transition: 50ms ease-in;
  transform: scale(1.05);
}

#workshop-date {
  margin-left: 20px;
  color: var(--color-orange);
  padding: 2px 4px;
}

.heading-container-facilitator {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
}

.heading-container-facilitator span {
  transition: 100ms ease-in;
  background-color: var(--color-orange);
  padding: 1px 10px;
  border-radius: 25px;
  font-size: 14px;
  color: white;
}

.heading-container-facilitator > .disabled {
  background-color: var(--color-disabled);
}

.heading-container-facilitator span:hover {
  filter: brightness(0.93);
}
.heading-container-facilitator > .disabled:hover {
  filter: brightness(1);
}
.heading-container-facilitator > * {
  margin-right: 15px;
}
.hidden {
  visibility: hidden;
}
</style>
