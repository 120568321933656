<template>
  <div class="question-container">
    <div @click.stop="this.toggleQuestion" class="question-heading">
      <font-awesome-icon
        :class="{'chevron-icon-up' : this.showAnswers}"
        class="chevron-icon-down"
        icon="fa-chevron-down"/>
      <p>{{ question.text_question?.[lang] }}</p>
    </div>
    <div :class="{'show-answers-container': this.showAnswers}" class="answers-container">
      <p class="answer-item" v-for="(answer,i) in question.answers" :key="i">{{ answer }}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'WorkshopQuestionComponent',
  props: {
    question: Object,
  },
  components: {},
  data() {
    return {
      showAnswers: false,
    };
  },
  computed: {
    lang() {
      return this.$i18n.locale;
    },
  },
  methods: {
    toggleQuestion() {
      this.showAnswers = !this.showAnswers;
    },
  },
};
</script>
<style scoped>
.question-container {
  user-select: none;
  width: 100%;
  height: 100%;
  padding-left: 35px;
}
.question-heading {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  cursor: pointer;
  transition: 100ms ease;
}

.question-heading:hover {
  color: orange;
}

.question-heading p {
  margin-left: 10px;
}
.answers-container {
  display: none;
  padding: 0 10px;
}

.show-answers-container {
  display: grid;
  height: 100%;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
}

.answer-item {
  min-height: 100px;
  width: 100%;
  background-color: rgba(255, 194, 102, 0.7);
  color: white;
  padding: 5px 10px;
  border-radius: 15px;
}

.chevron-icon-down {
  transition: 100ms ease;
}

.chevron-icon-up {
  transform: rotate(180deg);
}
</style>
